@import url(https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600&family=Roboto+Mono:wght@100;200;300;500;700&family=Source+Sans+3:wght@300;400;600;700&family=Ubuntu:wght@300;500;700&display=swap);
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.open .dropdown-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 8px;
  cursor: pointer;
}

li:hover {
  background-color: #a5b7c8;
}
